// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  apiUrl:"https://traveler.be.dev.atease.io",  
   url: '',
  // endpoint: 'https://atease-api-gateway-dev.pcsatease.com',
  // authendpoint: 'https://keycloak.atease.io/auth/realms/atease-admin/protocol/openid-connect/token',
  client_secret: 'LuqbKLbBfh9DLfGdrbRGJQ7c096Mr9HZ',
  client_id: 'atease-rest-client',
  // api:'http://localhost:8000/',
  // loginApp:'http://localhost:4200/'
  stripe: {
    publicKey: 'YOUR_PUBLIC_KEY',
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
