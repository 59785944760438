import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { map, catchError, tap, switchMap } from 'rxjs/operators';
import { StorageService } from 'src/app/services/storage.service';
import { CookieService } from 'ngx-cookie-service';

@Injectable()
export class AppInterceptor implements HttpInterceptor {
  private authHeader: string;
  constructor(private storage:StorageService,private cookieService: CookieService) {

  }
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    let clonedRequest = request.clone();

    // Check if the authHeader is set
    if (this.authHeader) {
      // If set, add it to the request headers
      clonedRequest = clonedRequest.clone({
        setHeaders: {
          Authorization: this.authHeader,
        },
      });
    }

    // Pass the modified request to the next interceptor or the backend
    return next.handle(clonedRequest).pipe(
      tap((event) => {
        // Check if the response is from the login API
        if (event instanceof HttpResponse && event.url.endsWith('/auth/login')) {
          // Extract and store the Authorization header for future use
          this.authHeader = event.headers.get('Authorization');
        }
      })
    );
  }
}
  
  // intercept(
  //   req: HttpRequest<any>,
  //   next: HttpHandler
  // ): Observable<HttpEvent<any>> {
  //   if (req.url.indexOf('/login') > -1) {
  //     return next.handle(req);
  //   }
  //   return next.handle(req).pipe(
  //     catchError((error) => {
  //       if (error.status == 401) {
  //         console.log(error)
  //         // return this.handle401Error(req, next, error);
  //       } else {
  //         return throwError(() => error);
  //       }
  //     })
  //   );
  // }
//}