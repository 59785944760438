import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { AppService } from 'src/app/app.service';
import { LoginComponent } from 'src/app/pages/login/login.component';
import { VerifyIdmeComponent } from 'src/app/pages/register/verify-idme/verify-idme.component';
import { ApiService } from 'src/app/services/api.service';
import { StorageService } from 'src/app/services/storage.service';
import { ApiResponse } from 'src/app/shared/models/response-model';

@Component({
  selector: 'app-user-menu',
  templateUrl: './user-menu.component.html',
  styleUrls: ['./user-menu.component.scss']
})
export class UserMenuComponent implements OnInit {
  profile:any= null;
  constructor(public appService:AppService,private storage:StorageService,
    public router:Router,public dialog: MatDialog,public apiservice: ApiService,public snackBar :MatSnackBar,
     ) { 
    // this.profile = storage.getData();
    // this.storage.userProfile.subscribe(profile => {
    //   this.profile = profile;
    this.profile = this.storage.getData();
    
    // });
  }

  ngOnInit() {
    this.storage.userProfile.subscribe(profile => {
      this.profile = profile;
    });
  }
  onLogout(){
    this.storage.removeLogin();
    this.profile = '';
    // window.location.href=environment.loginApp+'/logout';
    this.apiservice.postCall('/auth/logout', '').subscribe((res: ApiResponse<any>) => {
      if (res){
        this.snackBar.open('Logged out successfully!', '×', { panelClass: 'success', verticalPosition: 'top', duration: 3000 });
      }else{
        this.snackBar.open(`Something went Wrong ` +res.message, '×', { panelClass: 'error', horizontalPosition:'right', verticalPosition: 'top', duration: 3000 });
      }
    });
    this.router.navigate(['/']);
  }
  public openSigIn(message: string) {
    const dialogRef = this.dialog.open(LoginComponent, {
      maxWidth: "400px",
      data: message
    });
    return dialogRef;
  }
  onLoginClick(){
    this.onLogout();
  }

  public openSignup() {
    const dialogRef = this.dialog.open(VerifyIdmeComponent, {
      maxWidth: "400px",
    });
    // this.dialogRef.close()
    return dialogRef;
  }
  
}
