import { Component, OnInit, Output, EventEmitter, HostListener } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AppService } from 'src/app/app.service';
import { LoginComponent } from 'src/app/pages/login/login.component';
import { ApiService } from 'src/app/services/api.service';
import { animate, state, style, transition, trigger } from '@angular/animations';
@Component({
  selector: 'app-toolbar1',
  templateUrl: './toolbar1.component.html',
  animations: [
    trigger('shrinkTopHeader', [
      state('hide', style({
        height: '0',
        opacity: '0',
        overflow: 'hidden',
      })),
      state('show', style({
        height: '*',
        opacity: '1',
      })),
      transition('hide <=> show', animate('300ms ease-in-out')),
    ]),
    trigger('shrinkMainHeader', [
      state('hide', style({
        height: '0',
        opacity: '0',
        overflow: 'hidden',
      })),
      state('show', style({
        height: '*',
        opacity: '1',
      })),
      transition('hide <=> show', animate('300ms ease-in-out')),
    ]),
  ],
})
export class Toolbar1Component implements OnInit {
  @Output() onMenuIconClick: EventEmitter<any> = new EventEmitter<any>();
  constructor(public appService:AppService,public dialog: MatDialog, public ApiService : ApiService ) {
    //this.searchQuery = this.ApiService.getSearchState()
   // this.searchQuery = this.ApiService.getSearchState();
    this.ApiService.searchState$.subscribe((updatedSearchState) => {
      this.searchQuery = updatedSearchState;
    });
   }

  ngOnInit() {
    
   }

  public sidenavToggle(){
    this.onMenuIconClick.emit();
  }
  public openSignup(message: string) {
    const dialogRef = this.dialog.open(LoginComponent, {
      maxWidth: "400px",
      data: message
    });
    return dialogRef;
  }

    //animation
    topHeaderState = 'hide';
    mainHeaderState = 'show';
    searchQuery: any;
  
    @HostListener('window:scroll', ['$event'])
    onWindowScroll() {
      // Adjust the threshold as needed
      const scrollPosition = window.scrollY || document.documentElement.scrollTop;
  
      if (scrollPosition > 100) {
        this.topHeaderState = 'show';
        this.mainHeaderState = 'hide';
      } 
      else {
        this.topHeaderState = 'hide';
        // this.mainHeaderState = 'hide';
      }
    }
    //animation
  onSearchGuestButtonClick() {
    // Toggle states when the search button is clicked
    this.topHeaderState = this.topHeaderState === 'show' ? 'hide' : 'show';
    this.mainHeaderState = this.mainHeaderState === 'show' ? 'hide' : 'show';
    window.scrollTo({ top: 0, behavior: 'smooth' });

    this.ApiService.triggerSearchButtonGuestClick();
  }
  onSearchButtonCheckInClcik() {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    this.ApiService.triggerSeachCheckInClick();
  }
  onSearchButtonLocationClicked() {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    this.ApiService.triggerSeachLocationClick();
  }
}