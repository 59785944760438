
import { Component, OnInit } from '@angular/core';
import { LoginComponent } from '../../login/login.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-verify-idme',
  templateUrl: './verify-idme.component.html',
  styleUrls: ['./verify-idme.component.scss']
})
export class VerifyIdmeComponent implements OnInit {

  constructor(public dialog: MatDialog) { }

  ngOnInit(): void {
  }
  public openSignIn() {
    // Close the current register dialog
    this.dialog.closeAll();
  
    // Open the login dialog
    const dialogRef = this.dialog.open(LoginComponent, {
      maxWidth: "400px",
    });
  }
}
