enum PropertyTypes {
    Mature = 'mature',
    Developmental = 'developmental'
}
class PropertyImages{
    originalname:string;
    encoding:string;
    mimetype:string;
    size:number;
    fileUrl:string;
    constructor(){
        this.originalname = "";
        this.encoding = "";
        this.mimetype = "";
        this.size = (0);
        this.fileUrl = "";        
    }
}
export class AddPropertyModel {
    propertyType: PropertyTypes;
    area: number;
    name: string;
    totalPortfolio: number;
    assetsUnderManagement: number;
    holdPeriod: number;
    location: string;
    state: string;
    details: string;
    images:PropertyImages[];
    constructor() {
        this.propertyType = PropertyTypes.Developmental;
        this.area = (0);
        this.name = "";
        this.totalPortfolio = (0);
        this.assetsUnderManagement = (0);
        this.holdPeriod = 0;
        this.location = "";
        this.state = "";
        this.details = "";
        this.images = [];
    }
}

export class ApiResponse<T>{
    status!:number;
    isVerified!:boolean;
    message!:string;
    data!:T;
    enabled:boolean;
    email!:string;
    statusText!:string;
    statusCode: string
}
export interface Upload {
    progress: number
    state: 'PENDING' | 'IN_PROGRESS' | 'DONE'
  }
