import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class StorageService {
  public userProfile: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  constructor(private router:Router) { 
    const storedUserData = this.getData();
    if (storedUserData) {
      this.userProfile.next(storedUserData);
    }
  }

  addLogin(user:any){
    localStorage.setItem("user",JSON.stringify(user));
    // if(user.token)
    //   localStorage.setItem("token",user.token);
  }
  addToken(token){
    localStorage.setItem("token",token);
  }
  removeLogin(){
    localStorage.clear();
    this.userProfile.next(null); 
    this.router.navigate(['/account']);
  }
  getToken(){
    return localStorage.getItem("token");
  }
  getData(){
    return JSON.parse(localStorage.getItem("user"));
  }
}
