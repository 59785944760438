import { Component, OnInit, inject } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators} from '@angular/forms';
import { Router } from '@angular/router'; 
import { RegisterComponent } from '../register/register.component';
//import { MatDialog } from '@angular/material/dialog';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
// import { LoginRequestModel } from 'src/app/shared/models/login-model';
import { ApiResponse } from 'src/app/shared/models/response-model';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ApiService } from 'src/app/services/api.service';
import { StorageService } from 'src/app/services/storage.service';
import { LoginRequestModel } from 'src/app/shared/models/login-model';
import { environment } from 'src/environments/environment';
import { VerifyIdmeComponent } from '../register/verify-idme/verify-idme.component';
import { CookieService } from 'ngx-cookie-service';
import { HttpResponse } from '@angular/common/http';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  providers: [CookieService],
})
export class LoginComponent implements OnInit {
  cookieService = inject(CookieService);
  public loginForm: UntypedFormGroup;
  public hide = true;
  loginModel: LoginRequestModel;
  jwtToken: string;
  cookieValue: string;
  
  constructor(public fb: UntypedFormBuilder, public router: Router,
    private storage: StorageService,
    public snackBar: MatSnackBar,
    public api: ApiService,
    public dialog: MatDialog,public dialogRef: MatDialogRef<LoginComponent>,
    
    // private cookieService: CookieService
  ) {
   // this.cookieService.set('Test', 'Hello World');
    // this.cookieValue = this.cookieService.get('Test');
    // console.log(this.cookieValue)
   }

  ngOnInit() {
    this.loginForm = this.fb.group({
      // email: [null, Validators.compose([Validators.required, Validators.minLength(6)])],
      username: [null, Validators.compose([Validators.required, Validators.minLength(6)])],
      password: [null, Validators.compose([Validators.required, Validators.minLength(6)])],
      // rememberMe: false
    });
  }

  public onLoginFormSubmit(values: Object) {
    if (this.loginForm.valid) {
      this.loginModel = <LoginRequestModel>this.loginForm.value;
      this.api.postCall('/auth/login', this.loginModel).subscribe((res: ApiResponse<any>) => {
        if (res.isVerified == false) {
         this.jwtToken = this.cookieService.get('jwt');
         this.storage.addToken(this.jwtToken);
          this.storage.addLogin(res);
          this.storage.userProfile.next(res);
          this.snackBar.open('Login successfully!', '×', { panelClass: 'success',horizontalPosition:'right', verticalPosition: 'top', duration: 3000 });
        //   this.router.navigate(['/account']);
           const previousUrl = this.api.getPreviousUrl() || '/';
           console.log(previousUrl)
           this.router.navigateByUrl(previousUrl);
           // Clear the stored previous URL
           this.api.clearPreviousUrl();

             this.dialogRef.close()
        }
        else{
        this.snackBar.open(`Email or password not correct!  ` +res.message, '×', { panelClass: 'error', horizontalPosition:'right', verticalPosition: 'top', duration: 3000 });
        }
      })

    }
  }


  public openSignup() {
    const dialogRef = this.dialog.open(VerifyIdmeComponent, {
      maxWidth: "400px",
    });
    this.dialogRef.close()
    return dialogRef;
  }
 
}
