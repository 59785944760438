import { Injectable } from '@angular/core';
import { loadStripe, Stripe, StripeElements, StripeCardElement, PaymentMethod } from '@stripe/stripe-js';

@Injectable({
  providedIn: 'root'
})
export class StripeService {
  private stripePromise: Promise<Stripe | null>;
  private elements: StripeElements | null;
  private cardElement: StripeCardElement | null;

  private stripeAPIKey: string = 'pk_test_CpwWX9n3gPLXvdyPNm5KK6nk001hcOiYzS';
  private cardNumberElement: StripeCardElement | null;
  private cardExpiryElement: StripeCardElement | null;
  private cardCvcElement: StripeCardElement | null;
  constructor() {
    this.stripePromise = loadStripe(this.stripeAPIKey);
    this.initStripe();
  }
  async initStripe(): Promise<void> {
    if (!this.elements) {
      const stripe = await this.stripePromise;
      this.elements = stripe.elements();
      this.cardElement = this.elements.create('card');
      // this.cardElement.mount('#card-element');
    }
  }

  getStripe(): Promise<Stripe | null> {
    return this.stripePromise;
  }
  mountCardNumberElement(cardNumberElement: HTMLElement): void {
    if (this.cardNumberElement) {
      this.cardNumberElement.mount(cardNumberElement);
    }
  }

  mountCardExpiryElement(cardExpiryElement: HTMLElement): void {
    if (this.cardExpiryElement) {
      this.cardExpiryElement.mount(cardExpiryElement);
    }
  }

  mountCardCvcElement(cardCvcElement: HTMLElement): void {
    if (this.cardCvcElement) {
      this.cardCvcElement.mount(cardCvcElement);
    }
  }

  // async initStripe(): Promise<void> {
  //   if (!this.elements) {
  //     const stripe = await this.stripePromise;
  //     this.elements = stripe.elements();
  //     this.cardElement = this.elements.create('card');
  //     this.cardElement.mount('#card-element');
  //   }
  // }

  mountCardElement(cardElement: HTMLElement): void {
    if (this.cardElement) {
      this.cardElement.mount(cardElement);
      console.log(this.cardElement)
    }
  }

  async createPaymentMethod(clientSecret:string): Promise<{ paymentMethod: PaymentMethod | null; error: any }> {
    try {
      const { paymentMethod, error } = await (await this.stripePromise!).createPaymentMethod({
        type: 'card',
        card: this.cardElement!,
        billing_details: {
          name: 'Jenny Rosen'
        },
        metadata: {
          clientId: clientSecret
        }
      });

      if (error) {
        return { paymentMethod: null, error };
      } else {
        return { paymentMethod, error: null };
      }
    } catch (error) {
      return { paymentMethod: null, error: error.message };
    }
  }
}
