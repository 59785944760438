import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanActivateChild, CanDeactivate, CanLoad, Route, Router, RouterStateSnapshot, UrlSegment, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { ApiService } from 'src/app/services/api.service';
import { StorageService } from 'src/app/services/storage.service';

@Injectable({
  providedIn: 'root'
})
// auth-unprotected.guard.ts

// Import necessary modules

@Injectable({
  providedIn: 'root'
})
export class AuthUnprotectedGuard implements CanActivate {
  constructor(private storage: StorageService, private router: Router, private apiService: ApiService) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    // if (this.storage.getData()) {
    //   // User is already logged in, redirect to the landing page or another default page
    //   this.router.navigate(['/landing']);
    //   return false;
    // }
    this.apiService.setPreviousUrl(state.url);
    // Continue with navigation for unprotected routes
    return true;
  }
}
