import { Component, OnInit, Input, Output, EventEmitter, ViewChild, NgZone, HostListener, ElementRef, TemplateRef } from '@angular/core';
import { FormControl, UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { AppService } from '../../app.service';
import { MatSelect } from '@angular/material/select';
import { AgmMap, MapsAPILoader } from '@agm/core';
import { Router } from '@angular/router';
import { ApiService } from 'src/app/services/api.service';
import { MatDateRangePicker, MatDatepicker } from '@angular/material/datepicker';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { MatDialog } from '@angular/material/dialog';
@Component({
  selector: 'app-properties-search',
  templateUrl: './properties-search.component.html',
  styleUrls: ['./properties-search.component.scss'],
  animations: [
    trigger('shrinkTopHeader', [
      state('hide', style({
        height: '0',
        opacity: '0',
        overflow: 'hidden',
      })),
      state('show', style({
        height: '*',
        opacity: '1',
      })),
      transition('hide <=> show', animate('300ms ease-in-out')),
    ]),
    trigger('shrinkMainHeader', [
      state('hide', style({
        height: '0',
        opacity: '0',
        overflow: 'hidden',
      })),
      state('show', style({
        height: '*',
        opacity: '1',
      })),
      transition('hide <=> show', animate('300ms ease-in-out')),
    ]),
  ],
})
export class PropertiesSearchComponent implements OnInit {
  @ViewChild('myDialog') myDialogTemplate: TemplateRef<any>;
  //animation
  topHeaderState = 'hide';
  mainHeaderState = 'show';
  searchQuery: any;

  @HostListener('window:scroll', ['$event'])
  onWindowScroll() {
    // Adjust the threshold as needed
    const scrollPosition = window.scrollY || document.documentElement.scrollTop;

    if (scrollPosition > 100) {
      this.mainHeaderState = 'hide';
    }
     else {
      this.mainHeaderState = 'show';
    }
  }
  //animation

  @Input() variant: number = 1;
  @Input() vertical: boolean = false;
  @Input() searchOnBtnClick: boolean = false;
  @Input() removedSearchField: string;
  @Output() onSearchChange: EventEmitter<any> = new EventEmitter<any>();
  @Output() onSearchClick: EventEmitter<any> = new EventEmitter<any>();
  public showMore: boolean = false;
  public showFilter: boolean = false;
  showFiller = false;
  public form: UntypedFormGroup;
  public propertyTypes = [];
  public propertyStatuses = [];
  public cities = [];
  public neighborhoods = [];
  public streets = [];
  public features = [];
  public filteredPropertyTypes = [];
  public children: number = 0;
  public adults: number = 0;
  public guests: number = 0;
  public infant: number = 0;
  public dogs: number = 0;
  public cats: number = 0;
  @ViewChild('searchInput') searchInput: any;
  @ViewChild('checkInDate') checkInDatePicker: MatDatepicker<Date>;
  @ViewChild('checkOutDate') checkOutDate: MatDatepicker<any>;
  @ViewChild('checkInOutDatePicker') checkInOutDatePicker: MatDateRangePicker<Date>;
  @ViewChild('startDateInput') startDateInput: ElementRef;
  @ViewChild('endDateInput') endDateInput: ElementRef;
  @ViewChild(AgmMap) agmMap: AgmMap;
  searchControl = new FormControl();
  lat: any;
  long: any;
  isSearchVisible: boolean = true;
  public checkInOutRange : UntypedFormGroup
  constructor(public appService: AppService, public fb: UntypedFormBuilder, private mapsAPILoader: MapsAPILoader, private ngZone: NgZone, public router: Router, private ApiService: ApiService,private dialog: MatDialog) { }

  ngOnInit() {
    // this.MapSearchOption()
    this.initAutocomplete();

    if (this.vertical) {
      this.showMore = true;
    };
    this.propertyTypes = this.appService.getPropertyTypes();
    this.filteredPropertyTypes = this.propertyTypes;
    this.propertyStatuses = this.appService.getPropertyStatuses();
    this.cities = this.appService.getCities();
    this.neighborhoods = this.appService.getNeighborhoods();
    this.streets = this.appService.getStreets();
    this.features = this.appService.getFeatures();
   
    this.form = this.fb.group({
      travelType: null,
      governmentTravelType: null,

      propertyType: null,
      propertLocation: null,
      propertyStatus: null,
      guests: 0,
      children: 0,
      adults: 0,
      // checkInDate: null,
      // checkOutDate: null,
      // pcs:null,
      tdy: this.fb.group({
        travelingWithOthers: null,
        usingLodgingAllowance: false,
        usingMealAllowance: false,
      }),
      vacation: null,
      // mealAllowance:null,
      // bah: null,
      infant: 0,
      // dogs:0,
      // cats:0,


      // price: this.fb.group({
      //   from: null,
      //   to: null 
      // }),
      city: null,
      state: null,
      zipCode: null,
      // neighborhood: null,
      // street: null,
      // travelingWith:null,
      pcs: this.fb.group({
        mealAllowance: false,
        lodgingAllowance: false,
        usingBAH: false,
        travelingWith: null,

        propertyType: null,
        travelWith: this.fb.group({
          family: false,
          spouse: false,
          other: false
        }),
      
      }),
      checkInOutRange :this.fb.group({
        checkInDate: new FormControl<Date | null>(null),
        checkOutDate: new FormControl<Date | null>(null),
      })
      // bedrooms: this.fb.group({
      //   from: null,
      //   to: null 
      // }),
      // bathrooms: this.fb.group({
      //   from: null,
      //   to: null 
      // }),

      // garages: this.fb.group({
      //   from: null,
      //   to: null 
      // }),
      // area: this.fb.group({
      //   from: null,
      //   to: null 
      // }),
      // yearBuilt: this.fb.group({
      //   from: null,
      //   to: null 
      // }),       
      // features: this.buildFeatures(),

    });
    // this.checkInOutRange = this.fb.group({
    //   checkInDate: new FormControl<Date | null>(null),
    //   checkOutDate: new FormControl<Date | null>(null),
    // });
    // this.onSearchChange.emit(this.form);
    const savedSearchState = this.ApiService.getSearchState();
    // if (savedSearchState) {
    //   console.log(savedSearchState)
    this.form.patchValue(savedSearchState);
    this.adults = savedSearchState?.adults;
    this.children = savedSearchState?.children;
    this.infant = savedSearchState?.infant;
    this.guests = savedSearchState?.guests;
    // }
    this.subscribeToChanges();
    // this.form.get('checkInDate').valueChanges.subscribe((checkInDate) => {
    //   // Check if checkInDate has a value
    //   if (checkInDate) {
    //     // Open checkOutDate date picker
    //     this.checkOutDate.open();
    //   }
    // });
    // this.form.get('checkOutDate').valueChanges.subscribe((checkOutDate) => {
    //   // Check if checkInDate has a value
    //   if (checkOutDate) {
    //     // Open checkOutDate date picker
    //     this.showMore = true;
    //   }
    // });
    //holding the search click state
    this.ApiService.onSeachGuestClick$.subscribe(() => {
     this.showMore = true
    });
    this.ApiService.onSeachCheckInClick$.subscribe(() => {
      //this.checkInDatePicker.open();
      this.checkInDateOpen();
     
      console.log("checkin clicked")
    });
    this.ApiService.onSeachLocationClick$.subscribe(() => {
      //this.checkInDatePicker.open();
      this.searchInput.nativeElement.focus();
      console.log("checkin clicked")
    });

    this.form.valueChanges.subscribe(() => {
      // Check if both check-in and check-out dates are selected
      const checkInDate = this.form.get('checkInOutRange.checkInDate').value;
      const checkOutDate = this.form.get('checkInOutRange.checkOutDate').value;

      if (checkInDate && checkOutDate) {
        this.showMore = true;
      }
    });
  }
  
  toggleSearch() {
    this.isSearchVisible = !this.isSearchVisible;
  }
  public filterPropertyTypes(value: string) {
    const filterValue = value.toLowerCase();
    this.filteredPropertyTypes = this.propertyTypes.filter(
      (propertyType) => propertyType.name.toLowerCase().includes(filterValue)
    );
  }
  displayPropertyType(propertyType: any): string {
    return propertyType ? propertyType.name : '';
  }

  isLargeScreen(): boolean {
    return window.innerWidth >= 600;
  }
  public buildFeatures() {
    const arr = this.features.map(feature => {
      return this.fb.group({
        id: feature.id,
        name: feature.name,
        selected: feature.selected
      });
    })
    return this.fb.array(arr);
  }

  public checkInDateOpen() {
    this.startDateInput.nativeElement.focus(); 
    this.checkInOutDatePicker.open();
  }
  public checkOutDateOpen() {
    this.checkOutDate.open();
  }
  private subscribeToChanges(): void {
    this.form.get('governmentTravelType').valueChanges.subscribe((value) => {
      // Handle changes based on the selected value
      if (value === 'pcs') {
        // Update options for PCS
        this.form.get('tdy').reset(); // Reset TDY options
      } else if (value === 'tdy') {
        // Update options for TDY
        this.form.get('pcs').reset(); // Reset PCS options
      }
    });
  }
  ngOnChanges() {
    if (this.removedSearchField) {
      if (this.removedSearchField.indexOf(".") > -1) {
        let arr = this.removedSearchField.split(".");
        this.form.controls[arr[0]]['controls'][arr[1]].reset();
      }
      else if (this.removedSearchField.indexOf(",") > -1) {
        let arr = this.removedSearchField.split(",");
        this.form.controls[arr[0]]['controls'][arr[1]]['controls']['selected'].setValue(false);
      }
      else {
        this.form.controls[this.removedSearchField].reset();
      }
    }
  }
  openSelect(select: MatSelect) {
    if (!select.panelOpen) {
      select.open();
    }
  }
  public reset() {
    this.ApiService.resetSearchState();

    this.form.reset({
      propertyType: null,
      propertyStatus: null,
      guests: null,
      price: {
        from: null,
        to: null
      },
      city: null,
      zipCode: null,
      neighborhood: null,
      street: null,
      bedrooms: {
        from: null,
        to: null
      },
      bathrooms: {
        from: null,
        to: null
      },
      garages: {
        from: null,
        to: null
      },
      area: {
        from: null,
        to: null
      },
      yearBuilt: {
        from: null,
        to: null
      },
      features: this.features,
      checkInDate: null,
      checkOutDate: null,
    });
    this.adults = null;
    this.children = null;
    this.infant = null;

    this.guests = null;
  }

  public search() {
    // this.isSearchVisible = !this.isSearchVisible;
    this.showMore = false;
    this.ApiService.setSearchState(this.form.value);
    console.log(this.form.value)
    this.searchQuery = this.form.value;
    this.onSearchClick.emit();
    this.closeModal()
    this.router.navigate(['/property-filter'])


  }

  public onSelectCity() {
    this.form.controls['neighborhood'].setValue(null, { emitEvent: false });
    this.form.controls['street'].setValue(null, { emitEvent: false });
  }

  public onChildrenAddSelect() {
    const currentCount = this.form.controls['children'].value || 0;
    this.form.controls['children'].setValue(currentCount + 1, { emitEvent: false });
    this.children = currentCount + 1;
    this.guests = this.adults + this.infant + this.children;
    this.form.controls['guests'].setValue(this.guests)
  }

  public onChildrenRemoveSelect() {
    const currentCount = this.form.controls['children'].value || 0;
    if (currentCount > 0) {
      this.form.controls['children'].setValue(currentCount - 1, { emitEvent: false });
      this.children = currentCount - 1;
      this.guests = this.adults + this.infant + this.children;
      this.form.controls['guests'].setValue(this.guests)
    }
  }
  public onAdultAddSelect() {
    const currentCount = this.form.controls['adults'].value || 0;
    this.form.controls['adults'].setValue(currentCount + 1, { emitEvent: false });
    this.adults = currentCount + 1;
    this.guests = this.adults + this.infant + this.children;
    this.form.controls['guests'].setValue(this.guests)
  }
  public onAdultRemoveSelect() {
    const currentCount = this.form.controls['adults'].value || 0;
    if (currentCount > 0) {
      this.form.controls['adults'].setValue(currentCount - 1, { emitEvent: false });
      this.adults = currentCount - 1;
      this.guests = this.adults + this.infant + this.children;
      this.form.controls['guests'].setValue(this.guests)
    }
  }
  public oninfantAddSelect() {
    const currentCount = this.form.controls['infant'].value || 0;
    this.form.controls['infant'].setValue(currentCount + 1, { emitEvent: false });
    this.infant = currentCount + 1;
    this.guests = this.adults + this.infant + this.children;
    this.form.controls['guests'].setValue(this.guests)
    // this.onSearchClick.emit(); 
  }

  public oninfantRemoveSelect() {
    const currentCount = this.form.controls['infant'].value || 0;
    if (currentCount > 0) {
      this.form.controls['infant'].setValue(currentCount - 1, { emitEvent: false });
      this.infant = currentCount - 1;
      this.guests = this.adults + this.infant + this.children;
      this.form.controls['guests'].setValue(this.guests)
      //this.search()
    }
  }
  public onDogAddSelect() {
    const currentCount = this.form.controls['dogs'].value || 0;
    this.form.controls['dogs'].setValue(currentCount + 1, { emitEvent: false });

    this.dogs = currentCount + 1;
    //this.search()
  }

  public onDogRemoveSelect() {
    const currentCount = this.form.controls['dogs'].value || 0;
    if (currentCount > 0) {
      this.form.controls['dogs'].setValue(currentCount - 1, { emitEvent: false });
      this.dogs = currentCount - 1;
      //this.search()
    }
  }
  public onCatAddSelect() {
    const currentCount = this.form.controls['cats'].value || 0;
    this.form.controls['cats'].setValue(currentCount + 1, { emitEvent: false });
    this.cats = currentCount + 1;
    //this.search()
  }

  public onCatRemoveSelect() {
    const currentCount = this.form.controls['cats'].value || 0;
    if (currentCount > 0) {
      this.form.controls['cats'].setValue(currentCount - 1, { emitEvent: false });
      this.cats = currentCount - 1;
      //this.search()
    }
  }
  public onSelectNeighborhood() {
    this.form.controls['street'].setValue(null, { emitEvent: false });
  }

  public getAppearance() {
    return (this.variant != 3) ? 'outline' : '';
  }
  public getFloatLabel() {
    return (this.variant == 1) ? 'always' : '';
  }
  private updateGuests() {
    const children = this.form.controls['children'].value || 0;
    const adults = this.form.controls['adults'].value || 0;
    const infant = this.form.controls['infant'].value || 0;

    this.form.controls['guests'].setValue(children + adults + infant, { emitEvent: false });
  }
  // MapSearchOption(){
  //   //Map search option start
  //   this.searchControl.valueChanges.subscribe(searchQuery => {
  //    this.mapsAPILoader.load().then(() => {
  //      const geocoder = new google.maps.Geocoder();
  //      geocoder.geocode({ address: searchQuery }, (results, status) => {
  //        if (status === google.maps.GeocoderStatus.OK) {
  //          const { lat, lng } = results[0].geometry.location;
  //          console.log(results)
  //       }
  //      });
  //    });
  //  });
  // }
  initAutocomplete() {
    this.mapsAPILoader.load().then(() => {
      const autocomplete = new google.maps.places.Autocomplete(this.searchInput?.nativeElement, {
        types: ['address']  // Adjust the types based on your requirements
      });

      autocomplete.addListener('place_changed', () => {
        this.ngZone.run(() => {
          const place: google.maps.places.PlaceResult = autocomplete.getPlace();
          if (place.geometry === undefined || place.geometry === null) {
            return;
          }
          // this.checkInDatePicker.open();
          this.checkInOutDatePicker.open()
          const addressComponents = place.address_components;

          let city = '';
          let state = '';

          for (const component of addressComponents) {
            if (component.types.includes('locality')) {
              this.form.controls['propertLocation'].setValue(place.formatted_address)
              city = component.long_name;
              this.form.controls['city'].setValue(city)
            } else if (component.types.includes('administrative_area_level_1')) {
              state = component.long_name;
              this.form.controls['state'].setValue(state)
            }

          }
        });
      });
    });
  }
  onSearchButtonClick() {
    // Toggle states when the search button is clicked
    this.topHeaderState = this.topHeaderState === 'show' ? 'hide' : 'show';
    this.mainHeaderState = this.mainHeaderState === 'show' ? 'hide' : 'show';
  }


  openFilterAsModal(): void {

    const dialogRef = this.dialog.open(
      this.myDialogTemplate,
      {
        width: '100%',
        maxWidth: '99%',
         // Set the width as per your design preference
      }
    );

    dialogRef.afterClosed().subscribe(result => {
      // Handle the result when the modal is closed if needed
      console.log('Modal closed with result:', result);
    });
  }
  closeModal() {
    this.dialog.closeAll()
  }
}
