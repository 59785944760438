import { HttpClient, HttpErrorResponse, HttpEvent, HttpEventType, HttpHeaders, HttpProgressEvent, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Data, Router } from '@angular/router';
import { BehaviorSubject, Observable, Subject, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { ApiResponse, Upload } from '../shared/models/response-model';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Property } from '../app.models';
import { DatePipe } from '@angular/common';
//import { ApiResponse, Upload } from '../models/models';


@Injectable({
  providedIn: 'root'
})
export class ApiService {
  apiUrl:string = environment.apiUrl;
  private access_token: string;
  private formDataKey = 'formDataKey';
  private searchQuery = 'searchQuery'
  public searchState: any = {};
  private previousUrl: string | null = null;
  private onSearchButtonGuestClickSource = new Subject<void>();
  onSeachGuestClick$ = this.onSearchButtonGuestClickSource.asObservable();
  private searchStateSubject = new Subject<any>();
  searchState$ = this.searchStateSubject.asObservable();

  private onSeachCheckInClick = new Subject<void>();
  onSeachCheckInClick$ = this.onSeachCheckInClick.asObservable();
  
  private onSeachLocationClick = new Subject<void>();
  onSeachLocationClick$ = this.onSeachLocationClick.asObservable();

  constructor(private http:HttpClient,private router:Router, public snackBar: MatSnackBar,private datePipe: DatePipe) { }

  getCall<T>(url:string):Observable<ApiResponse<T>>{
    return this.http.get<ApiResponse<T>>(this.apiUrl+url,{withCredentials: true}).pipe(map(response=>{ 
      if(response.status === 401){
        //this.router.navigate(['/un-authorized']);
        return new ApiResponse();
      }
      return response
    }));
  }
  public getProperties(data: any): Observable<any>{
    if (data) {
      data.checkInDate = this.datePipe.transform(data?.checkInDate, 'yyyy-MM-dd');
      data.checkOutDate = this.datePipe.transform(data?.checkOutDate, 'yyyy-MM-dd');
      // ... other logic
    } else {
      console.error('Data is null or undefined.');
    }
//   return this.http.get<any>(this.apiUrl + `/search?checkin=${data.checkInDate}&checkout=${data.checkOutDate}&city=${data.city}&state=${data.state}&zipcode=${data.zipCode}&totalGuests=${data.guests}`);
    return this.http.get<any>(this.apiUrl + `/search?checkin=2023-12-16&checkout=2023-12-20&city=Chandler&state=az&zipcode=85224&totalGuests=1`);
  }
  postCall<T>(url: string, data: any): Observable<ApiResponse<T>> {
    return this.http.post<ApiResponse<T>>(this.apiUrl + url, data,{withCredentials: true })
      .pipe(
        catchError((error: HttpErrorResponse) => this.handleHttpError(error))
      );
  }
  putCall<T>(url: string, data: any): Observable<ApiResponse<T>> {
    return this.http.put<ApiResponse<T>>(this.apiUrl + url, data,{withCredentials: true })
      .pipe(
        catchError((error: HttpErrorResponse) => this.handleHttpError(error))
      );
  }
  getCallById<T>(url:string):Observable<ApiResponse<T>>{
    return this.http.get<ApiResponse<T>>(this.apiUrl+url).pipe(map(response=>{ 
      if(response.status === 401){
        //this.router.navigate(['/un-authorized']);
        return new ApiResponse();
      }
     
      return response
    }));
  }
  public getPropertyById(id): Observable<Property>{
    return this.http.get<Property>(this.apiUrl + id);
  }
  DeleteCallById<T>(url:string):Observable<ApiResponse<T>>{
    return this.http.get<ApiResponse<T>>(this.apiUrl+url).pipe(map(response=>{ 
      if(response.status === 401){
        //this.router.navigate(['/un-authorized']);
        return new ApiResponse();
      }
     
      return response
    }));
  }

  isHttpResponse<T>(event: HttpEvent<T>): event is HttpResponse<T> {
    return event.type === HttpEventType.Response
  }
  
  isHttpProgressEvent(
    event: HttpEvent<unknown>
  ): event is HttpProgressEvent {
    return (
      event.type === HttpEventType.DownloadProgress ||
      event.type === HttpEventType.UploadProgress
    )
  }
  upload(data: FormData): Observable<any> {

    const initialState: Upload = { state: 'PENDING', progress: 0 }
    const calculateState = (upload: Upload, event: any): Upload => {
      if (this.isHttpProgressEvent(event)) {
        return {
          progress: event.total
            ? Math.round((100 * event.loaded) / event.total)
            : upload.progress,
          state: 'IN_PROGRESS',
        }
      }
      if (this.isHttpResponse(event)) {
        return {
          progress: 100,
          state: 'DONE',
        }
      }
      return upload
    }
    return this.http
      .post(environment.apiUrl+'upload',data)
      //.pipe(scan(calculateState,initialState))
  }
  setAccessToken(token: string): void {
    this.access_token = token;
  }

  private handleHttpError(error: HttpErrorResponse): Observable<ApiResponse<any>> {
    if (error.error && error.error.error) {
   console.log(error)
      // Handle specific error messages from the API
      this.snackBar.open(error.error.error, '×', { panelClass: 'error', horizontalPosition: 'right', verticalPosition: 'top', duration: 3000 });
    //  return of({ status: error.status, data: null, message: error.error.error, error: true });
    } else {
      // Handle generic error messages
      this.snackBar.open('An unexpected error occurred. Please try again later.', '×', { panelClass: 'error', horizontalPosition: 'right', verticalPosition: 'top', duration: 3000 });
    }
  
    // Return an observable with an ApiResponse indicating failure
    return of({ status: error.status, data: null,isVerified:false, message: error.error, error: true,email:null, enabled:null,statusText:null,authenticateIDMETokenResults:null, statusCode:null});
  }


  // private formDataSubject = new BehaviorSubject<any>(null);
  // formData$ = this.formDataSubject.asObservable();

  // setFormData(data: any) {
  //   this.formDataSubject.next(data);
  // }



  // setSearchState(searchState: any): void {
  //   localStorage.setItem('searchQuery', JSON.stringify(searchState));
  //   this.searchStateSubject.next(searchState);
  // }

  setSearchState(state: any) {
    this.searchState = state;
    this.searchState.checkInDate= this.datePipe.transform( this.searchState.checkInDate, 'yyyy-MM-dd');
    this.searchState.checkOutDate= this.datePipe.transform( this.searchState.checkOutDate, 'yyyy-MM-dd');
    localStorage.setItem(this.searchQuery,JSON.stringify(state))
    this.searchStateSubject.next(this.searchState);
  }
  resetSearchState() {
    localStorage.removeItem(this.searchQuery);
    this.searchStateSubject.next(this.searchState);
    this.searchState = {
      // Set default values or an empty state if needed
      checkInDate: null,
      checkOutDate: null,
      // Add other properties as needed
    };
   
  }


  getSearchState(): any {
    const searchQuery = localStorage.getItem(this.searchQuery);
    return searchQuery ? JSON.parse(searchQuery) : null;
  }

  

  public formDataSubject = new BehaviorSubject<any>(this.getStoredFormData());
  formData$ = this.formDataSubject.asObservable();

  setFormData(data: any) {
    localStorage.setItem(this.formDataKey, JSON.stringify(data));
    this.formDataSubject.next(data);
  }

  public getStoredFormData(): any {
    const storedData = localStorage.getItem(this.formDataKey);
    return storedData ? JSON.parse(storedData) : null;
  }
  public SetBookingConfirmationStatus(bookingConfirmation:any){
   localStorage.setItem('bookingConfirmation', JSON.stringify(bookingConfirmation))
  }
  public GetBookingConfirmationStatus():any{
    const bookingConfirmationResponse = localStorage.getItem('bookingConfirmation')
    return bookingConfirmationResponse ? JSON.parse(bookingConfirmationResponse): null;
  }



  triggerSearchButtonGuestClick() {
    this.onSearchButtonGuestClickSource.next();
  }
  triggerSeachCheckInClick() {
    this.onSeachCheckInClick.next();
  }
  triggerSeachLocationClick() {
    this.onSeachLocationClick.next();
  }

  setPreviousUrl(url: string): void {
    this.previousUrl = url;
    console.log(this.previousUrl)
  }

  getPreviousUrl(): string | null {
    return this.previousUrl;
  }

  clearPreviousUrl(): void {
    this.previousUrl = null;
  }
}

